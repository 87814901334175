import { useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Footer from './Footer';
import { Header } from './Header';
import { MainContent } from './MainContent';
import { Navigation } from './Navigation';

export function MasterPage() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <div style={{backgroundImage: '%PUBLIC_URL%/1080p_desert.jpg', backgroundRepeat: 'repeat'}}>
            {isSmallScreen ? <Header /> : ''}
            <div className="footer-adjacent-container">
                <Grid
                    container
                    direction={'row'}
                    spacing={1}
                    style={{ paddingTop: theme.spacing(2), paddingLeft: isSmallScreen ? theme.spacing(2) : theme.spacing(12) }}
                >
                    <Grid md={3} display={{ xs: 'none', sm: 'none', md: 'block' }}>
                        <Navigation />
                    </Grid>

                    <Grid xs={12} md={8}>
                        <MainContent />
                    </Grid>
                </Grid>
            </div>

            <Footer />
        </div>
    );
}
