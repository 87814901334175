import {
    Divider,
    Icon,
    ImageList,
    ImageListItem,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    useTheme
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Section from './Section';

export function About() {
    const theme = useTheme();

    interface specialty {
        text: string;
        secondaryText?: string;
        icon: string;
    }

    const specialties: specialty[] = [
        { text: 'Certified Industrial Hygiene Services', icon: 'construction' },
        { text: 'Remedial Action Studies and Plans', icon: 'home_repair_service' },
        { text: 'Health and Safety Plans', icon: 'health_and_safety' },
        { text: 'Indoor Air Pollution (Tight Build and Sick Building Syndromes)', icon: 'masks' },
        { text: 'Certified Safety Inspections (both manufacturing and construction', icon: 'search' },
        { text: 'Asbestos Material Identification and Abatement Management', icon: 'coronavirus' },
        { text: 'Environmental Audits and Site Assessments', icon: 'shield' },
        { text: 'Governmental Agency Liaison', icon: 'gavel' },
        { text: 'Expert Witness Services', icon: 'assured_workload' },
        { text: 'Training in OSHA/RCRA/DOT', icon: 'support' }
    ];

    const degrees: specialty[] = [
        { text: 'Certified Industrial Hygenist', icon: 'coronavirus', secondaryText: 'CIH' },
        { text: 'Certified Safety Professional', icon: 'health_and_safety', secondaryText: 'CSP' },
        { text: 'Certified Environmental Trainer', icon: 'school', secondaryText: 'CET' },
        { text: 'OSHA / EPA HAZWOP/ER and Supervisory Training', icon: 'school', secondaryText: '29 CFR 1910.120' },
        { text: 'FEMA Disaster / Emergency Management / Manager Training', icon: 'school' },
        {
            text: 'EPA / AHERA Asbestos Building Inspector and Contractor / Supervisor',
            icon: 'home_repair_service'
        }
    ];

    return (
        <Section id="about" title="Who we are">
            <div style={{ position: 'relative' }}>
                <ImageList>
                    <ImageListItem>
                        <img src={'ApacheNitrogen.jpg'} alt="Apache Nitrogen" />
                    </ImageListItem>

                    <ImageListItem>
                        <img src={'microbe_hazard_small.jpg'} alt="Microbe Hazard Site" />
                    </ImageListItem>
                </ImageList>
            </div>


            <p>
                BRITER is an environmental, chemical and industrial hygiene/safety consulting firm. We offer our clients personalized service at competitive prices. Our firm specializes in:
            </p>

            <Grid container direction={'row'}>
                <Grid xl={6} lg={12}>
                    <List dense={true} >
                        {specialties.map((specialty, index) => (
                            <ListItem key={index}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <Icon>{specialty.icon}</Icon>
                                    </ListItemIcon>
                                    <ListItemText primary={specialty.text} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Grid>

                <Grid xl={6} display={{  xs: 'none', md: 'none', xl: 'block' }}>
                    <img src={'cih_images/Aircraft Painting.jpg'} alt="Aircraft Painting" style={{maxWidth: '90%', paddingTop: '19px'}} />
                </Grid>
            </Grid>




            <Grid container direction={'row'} padding={3}>
                <Grid xl={4} display={{  xs: 'none', md: 'none', xl: 'block' }}>
                    <img src={'cih_images/MineFlocculation.jpg'} alt="Cleaning Solvents" style={{ maxWidth: '90%' }} />
                </Grid>

                <Grid xl={8} lg={12}>
                    <p>
                        BRITER staff are trained professionals, holding national board certifications in Industrial Hygiene, Safety and Environmental Training. Our staff is available to provide insightful evaluation and knowledgeable recommendations to your OSHA and EPA compliance situations.
                    </p>
                    <p>
                        Our approach to problem-solving is to obtain a detailed understanding of your facilities
                        processes, the operations and raw materials being utilized. This information will be translated into the collection of meaningful data and formulated into a comprehensive remediation / compliance plan.
                    </p>
                    <p>
                        BRITER staff are experienced in providing these services using state-of-the-art test equipment, while practicing full quality control procedures.
                    </p>
                    <p>
                        Additionally, our staff members interface well with all levels of management - both private
                        industry and governmental - to assure the successful completion of your project.
                    </p>
                </Grid>
            </Grid>



            <Typography variant={'h5'} style={{ paddingTop: theme.spacing(2) }}>
                Degree's and Certifications
            </Typography>
            <Divider />
            <br />
            All BRITER staff hold Bachelors in Industrial Hygiene (Health-Related Professions/Occupational Health and Safety). Additionally,
            we hold national board certifications and registrations in the following areas:
            <List dense={true}>
                {degrees.map((specialty, index) => (
                    <ListItem key={index}>
                        <ListItemButton>
                            <ListItemIcon>
                                <Icon>{specialty.icon}</Icon>
                            </ListItemIcon>
                            <ListItemText primary={specialty.text} secondary={specialty.secondaryText} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Section>
    );
}
