import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Divider, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { NavigationState } from '../redux/navigationSlice';
import { RootState } from '../redux/store';
import NavScrollButton from './NavScrollButton';

export function Navigation() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xl'));
    const navigationState: NavigationState = useSelector((state: RootState) => state.navigation);

    return (
        <div className={'briter-glass-background'} style={{ position: 'sticky', top: theme.spacing(1), minWidth: '209px' }}>
            <Stack direction={'column'}>
                <Typography variant={'h4'} align={'center'} className={'logo-text'}>
                    BRITER
                </Typography>

                <Typography variant={'h4'} align={'center'} className={'logo-text'}>
                    Environmental Consultants
                </Typography>

                {navigationState.sections.map((section) => {
                    return <NavScrollButton key={section.id} {...section} />;
                })}

                <Box pt={theme.spacing(3)} />
                <Divider />

                <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2} sx={{ padding: theme.spacing(2) }}>
                    <Button variant={'outlined'} startIcon={<FontAwesomeIcon icon={faPhone} href={'tel:5202356547'} />}>
                        (520) 235-6547
                    </Button>

                    <Box sx={{ flexGrow: 1 }} />

                    <Button variant={'outlined'} startIcon={<FontAwesomeIcon icon={faEnvelope} />} href={'mailto:briter@cox.net'} style={{minWidth: '170px'}}>
                        briter@cox.net
                    </Button>
                </Stack>
            </Stack>
        </div>
    );
}
