import { Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { InView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { addSection, setNavigationPoint } from '../../redux/navigationSlice';

type SectionProps = {
  title: string;
  id: string;
  children: React.ReactNode;
};

export default function Section( props: SectionProps ) {

  const theme = useTheme();
  const dispatch = useDispatch();

  // Inform the navigation state that a section needs to be registered
  useEffect( () => {
    dispatch( addSection( { id: props.id, title: props.title } ) );
  });

  function setIsInView( isInView: boolean ) {
    if ( isInView ) {
      dispatch( setNavigationPoint( props.id ) );
    }
  }

  const isSmallScreen = useMediaQuery( theme.breakpoints.down( 'xl' ) );

  return (
    <InView id={props.id}
      className={'briter-glass-background'}
      style={{ padding: theme.spacing( 2 ) }}
      onChange={( inView ) => setIsInView( inView )}
      threshold={[isSmallScreen ? 0.10 : 0.45] }>

      
      <Divider component={'div'} role={'presentation'} style={{ paddingBottom: '1em' }}>
        <Typography variant={'h4'} align={'center'} className={'circled'}>{props.title}</Typography>

      </Divider>


      {props.children}
    </InView>
  );
}