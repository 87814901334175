import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';

type ShowMoreTextProps = {
    maxWordLength?: number;
    children: React.ReactNode;
};

export default function ShowMoreText(props: ShowMoreTextProps) {
    const [text, setText] = useState('');
    const [expanded, setExpanded] = useState(false);
    const [enabled, setEnabled] = useState(true);
    const [maxWordLength] = useState(props.maxWordLength ? props.maxWordLength : 12);

    function buildText() {
        let finalText = '';

        if (!expanded) {
            // Load some words, delimited by spaces
            const textArray = (props.children + '').split(' ');

            finalText = textArray.slice(0, maxWordLength).join(' ');

            // Check if there are enough words to warrant a Show More button
            let hasEnoughWords = textArray.length > maxWordLength;
            if (hasEnoughWords) {
                finalText = finalText + '...';
            } else {
                setEnabled(hasEnoughWords);
            }
        } else {
            // We are expanded so show everything
            finalText = props.children + '';
        }

        setText(finalText);
    }

    useEffect(() => {
        buildText();
    });

    return (
        <span>
            {text}
            {enabled && (
                <>
                    <br />
                    <Button
                        variant="text"
                        size={'small'}
                        onClick={() => {
                            setExpanded(!expanded);
                        }}
                    >
                        {expanded ? 'Show Less' : 'Show More'}
                    </Button>
                </>
            )}
        </span>
    );
}
