import React from 'react'
import { NavigationData, NavigationState } from '../redux/navigationSlice'
import { Button } from '@mui/material'
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';


export default function NavScrollButton(section: NavigationData) {
    const navigationState: NavigationState = useSelector((state: RootState) => state.navigation);

    function scrollToSection(section: NavigationData) {
        const element = document.getElementById(section.id);

        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    return (
        <Button variant={navigationState.sectionInView === section.id ? 'contained' : 'text'}
            onClick={() => scrollToSection(section)}>
            {section.title}
        </Button>
    )
}