import {
    Icon,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
    useTheme
} from '@mui/material';
import Section from './Section';

export default function Asbestos() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    function renderAsbestosImage() {
        if (isSmallScreen) {
            return (
                <img src={'Asbestos_Fiber.jpg'} alt={'asbestos fiber'}
                     style={{ maxHeight: '240px', paddingLeft: theme.spacing(3), paddingTop: theme.spacing(1) }} />
            );
        } else {
            return (
                <img src={'Asbestos_Fiber.jpg'} alt={'asbestos fiber'}
                     style={{ float: 'right', paddingLeft: theme.spacing(2) }} />
            );
        }
    }

    const asbestosServices: string[] = [
        'Building Inspection following AHERA Regulations',
        'Building Inspections using the ASTM E2356-2014 Guidelines',
        'NESHAP required Building Inspection needed prior to Renovation and Demolitions',
        'AHERA Schools Inspections and Management Plans',
        'Abatement Project Management',
        'Abatement Final Clearance Sampling for AHERA Projects (TEM sampling)',
        'Abatement Final Clearance Sampling following NESHAP Projects (PCM sampling)'
    ];


    return (
        <Section id={'asbestos'} title={'Asbestos and Mold'}>
            {renderAsbestosImage()}

            <p>
                Asbestos was described as a “miracle fiber” in its heyday. It was used in over thirty-five hundred different
                products to strengthen, fire proof, reduce noise transmission, insulate and decorate. It was used in water and flue pipes,
                sprayed-on decorative and acoustical materials, to strengthen concrete slabs, in floor tiles, mastics, window glazing and roofing
                materials, to name but a few. Health effects caused by the mining, milling, manufacturing and installation of these asbestos-containing
                products eventually caused its fall from grace.
            </p>
            <p>
                BRI+TER can assist you in the identification of asbestos-containing materials located inside and on your building. We follow strict
                protocols established under the Asbestos Hazard Emergency Response Act (AHERA) regulatory standards in the collection, and analysis
                of your building samples. As an alternative, we can also use the ASTM E2356-2014 guideline in both the collection and analysis of
                these bulk samples. This ASTM guideline can offer a more definitive outcome to better answer the question…Is this an
                asbestos-containing material.
            </p>

            <p>BRITER can provide the following asbestos related services:</p>

            <List dense={true}>
                {asbestosServices.map( ( service, index ) =>
                    <ListItem key={index}>
                        <ListItemButton>
                            <ListItemIcon>
                                <Icon>coronavirus</Icon>
                            </ListItemIcon>
                            <ListItemText primary={service} />
                        </ListItemButton>
                    </ListItem>
                )}
            </List>

        </Section>
    );
}