import {createTheme} from "@mui/material";

const briterGlass = {
  backgroundColor: 'rgba(26, 26, 26, 0.80)',
  backdropFilter: 'blur(10px)'
}

const briterGlassLevelTwo = {
  backgroundColor: 'rgba(26, 26, 26, 0.2)',
}

export const appTheme = createTheme(
  {
    palette: {
      primary: {
        main: '#ffffff'
      },
      secondary: {
        main: '#da1414'
      },
      mode: 'dark',
      background: {
        paper: 'rgb(42,42,42)'
      }
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: briterGlass
        }
      },
      MuiCard: {
        styleOverrides: {
          root: briterGlassLevelTwo
        }
      }
    }
  }
);