import {faWind} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import Section from './Section';

type Props = {

};

export function IndoorAirQuality(props: Props) {

    const samplingAreas: string[] = [
        "HVAC system general air flow performance testing",
        "Carbon dioxide monitoring looking for voids in HVAC performance and or delivery",
        "Specific or generalized Volatile Organic Compound (VOC) sampling and identification",
        "Formaldehyde and 4-PC (carpet off-gas) area air monitoring",
        "Carbon Monoxide monitoring looking at unwanted combustion byproducts and sources",
        "Total or inhalable particulate concentrations in the indoor environment(s)",
        "Problematic airborne and or surface fungal concentrations",
        "Investigations into specific indoor air quality complaints and or situations",
        "Public Relations issued created by the perception of poor indoor air quality",
    ]

    return (
        <Section id='indoorAirQuality' title='Indoor Air Quality'>
            <p>
                Indoor air quality issues began back in the 1990’s with the advent of higher fuel and energy costs.
                These higher fuel costs translated to owners tightening their buildings to lighten the burden placed
                upon them by higher heating and cooling costs. Fresh air intake was minimized in order to lower
                electric and natural gas usage and cost. Tight Building Syndrome (TBS) became a national problem
                within a few years and placed an extreme burden upon staff members that was until this point in time
                basically unheard of.
            </p>

            <p>
                With the realization of this unique situation, the ASHRAE (American Society of Heating, Refrigeration
                and Air Conditioning Engineers) replaced older standards with those that looked towards carbon dioxide
                concentrations found indoors as indicators of problematic indoor air quality. The older ASHRAE standard
                using carbon dioxide directly has since been replaced with recommendations for fresh air intake based
                upon occupancy and square footage. While most of the problems with TBS have been abated with ventilation
                changes, the area of indoor air quality is still not without its challenges.
            </p>

            BRITER can provide insight and air sampling in the following areas to assist you in the determination
            of your building, floor or wing, its HVAC system and overall indoor air quality:
            <List>
                {samplingAreas.map((area, index) =>
                    <ListItemButton key={index}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faWind} />
                        </ListItemIcon>
                        <ListItemText>{area}</ListItemText>
                    </ListItemButton>
                )}
            </List>

        </Section>
    );
};