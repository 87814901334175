import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Section from './Section';


export default function Staff() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

    function renderStaffImage() {
        if (isSmallScreen) {
            return (
                <Grid container justifyContent={'center'}>
                    <img src={'brian_casual.jpg'} alt={'Brian Gladhart'}
                        style={{ maxWidth: '100%', maxHeight: '425px' }} />
                </Grid>
            );
        } else {
            return (
                <img src={'brian_casual.jpg'} alt={'Brian Gladhart'}
                     style={{ float: 'right', maxHeight: '460px', paddingLeft: theme.spacing(2) }} />
            );
        }
    }

    return (
        <Section id={'staff'} title={'Staff'}>
            <Typography variant={'h6'}>Brian Gladhart, CEO of <b>BRITER</b></Typography>

            
            {renderStaffImage()}

            

            <p>
                Current CEO and President of BRITER Environmental, Brian has served the Tucson community since
                1992 helping people all across the mid-west. Besides a bachelor’s degree in Industrial Hygiene,
                Brian maintains professional certifications in several key areas (MSHA, asbestos, emergency response and
                FEMA).
            </p>

            <p>
                Alongside his professional work, Brian also taught students as a primary instructor for the largest
                training provider
                in the Southwest in topics for the U.S. Environmental Protection Agency Asbestos Hazard Emergency
                Response Act courses that
                include Contractor/Supervisor, Operations and Maintenance and Building Inspector.
            </p>

            <p>
                BRITER is a home-grown business built from the ground up and supported by years of great service and reference from satisfied clients.
                From the local schoolhouse to the industrial airfield, Brian has taken samples, professionally evaluated the outcomes and prepared reports
                to concisely explain the problems and their circumstances in the workplace. We can then offer advice on how best to proceed taking care of
                the situation in a professionally responsible manner that can, in most instances easily be implemented in your workplace.
            </p>
        </Section>
    );
}