import {Box, Stack, Typography, useTheme} from '@mui/material';


export default function Footer() {

    const theme = useTheme();

    return (
        <>
            <Box sx={{paddingTop: theme.spacing(3)}}/>


            <div className="briter-glass-background" style={{padding: theme.spacing(3)}}>
                <Stack direction='row' spacing={3} justifyContent='center' alignItems='center'>
                    <Typography variant={'body1'}>
                        BRITER Environmental Inc. | Chemical Consultants, Air Quality and Asbestos
                    </Typography>

                    <Typography variant='subtitle1' color={'textSecondary'}>
                        All content is owned or is used with permission by the original owner
                    </Typography>

                    <img src={'jesus_fish_darkmode.svg'} alt={'Jesus Christ is Lord'} style={{maxHeight: '25px'}}/>
                </Stack>
            </div>
        </>
    )
}