import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography, useTheme } from '@mui/material';
import ShowMoreText from '../ShowMoreText';
import { BriterCardData } from './briterCard.type';

/**
 * Re-usable configurable Card component that fills the available height so all cards are the same
 * height as their sibling cards.
 *
 * @param props
 */
export default function BriterCard(props: BriterCardData) {
    const theme = useTheme();

    function renderCardMedia(image: string, imageHeight: number | undefined, objectFit: string | undefined) {
        // If no imageHeight is given we have to have SOME height or the image wont render.
        if (!imageHeight) {
            imageHeight = 140;
        }

        if (!objectFit) {
            objectFit = 'fill';
        }

        let paddingObject = {};
        if ( props.imagePadding ) {
            paddingObject = { padding: props.imagePadding };
        }


        return <CardMedia component={'img'} sx={{ height: imageHeight, objectFit: objectFit, ...paddingObject }} image={image} />;
    }

    function renderBodyText() {
        if (props.limitTextInitially) {
            return <ShowMoreText>{props.body}</ShowMoreText>;
        } else {
            return props.body;
        }
    }

    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }} elevation={5}>
            {props.image && renderCardMedia(props.image, props.imageHeight, props.imageObjectFit)}

            <Box sx={{ flex: '1 0 auto' }}>
                <CardContent>
                    <Typography variant="h5">{props.title}</Typography>
                    <Typography variant="subtitle1" color={'text.secondary'}>{props.subtitle}</Typography>
                    <Box sx={{ paddingBottom: theme.spacing(2) }} />

                    <Typography gutterBottom variant={'body2'} color={'text.secondary'}>
                        {renderBodyText()}
                    </Typography>
                </CardContent>
            </Box>

            {props.learnMoreLink && (
                <CardActions>
                    <Button size={'small'} href={props.learnMoreLink} target={'_blank'}>
                        Learn More
                    </Button>
                </CardActions>
            )}

            {props.visitLink && (
                <CardActions>
                    <Button size={'small'} href={props.visitLink} target={'_blank'}>
                        Visit Homepage
                    </Button>
                </CardActions>
            )}
        </Card>
    );
}
