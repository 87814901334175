import React from 'react';
import './App.css';
import { MasterPage } from './components/MasterPage';

function App() {
  return (
    <div>
      <MasterPage />
    </div>
  );
}

export default App;
