import {Container, Stack} from "@mui/material";
import Asbestos from "./sections/Asbestos";
import Clients from "./sections/Clients";
import Staff from "./sections/Staff";
import {About} from "./sections/About";
import {CIH} from "./sections/CIH";
import {IndoorAirQuality} from "./sections/IndoorAirQuality";

export function MainContent() {

  return (
    <Container maxWidth={false}>
      <Stack direction={"column"} spacing={2}>
        <About/>
        <Staff/>
        <CIH/>
        <Clients />
        <IndoorAirQuality />
        <Asbestos />
      </Stack>
    </Container>
  );
}