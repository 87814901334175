import {PayloadAction, createSlice} from "@reduxjs/toolkit";

// The important info we need for each NavigationSection component
export type NavigationData = {
  id: string;
  title: string;
}

export type NavigationState = {
  sections: NavigationData[]; // string array of IDs that point to each section component rendered
  sectionInView: string; // an HTML ID that exists in 'sections' that is in the middle of the screen
}


const initialState: NavigationState = {
  sections: [],
  sectionInView: 'about'
}

/**
 * Represents the sections on the page and their order and which section is
 *  currently in the middle of the page
 */
const navigationSlice = createSlice( {
  name: 'navigation',
  initialState,
  reducers: {
    addSection: (state, action: PayloadAction<NavigationData>) => {
      // Only push a section if it doesn't already exist in the list.
      if (!state.sections.some(navData => navData.id === action.payload.id)) {
        state.sections.push(action.payload);
      }

      return state;
    },
    setNavigationPoint: (state, action: PayloadAction<string>) => {
      state.sectionInView = action.payload;
      return state;
    }  
  }
});


export const { addSection, setNavigationPoint } = navigationSlice.actions;
export default navigationSlice.reducer;