// @flow
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Box, IconButton, Stack, Toolbar, Typography } from '@mui/material';

export function Header() {
    return (
        <AppBar position={'sticky'}>
            <Toolbar>
                <Typography variant={'h6'} align={'center'} className={'logo-text'}>
                    BRITER Environmental
                </Typography>


                <Box sx={{ flexGrow: 1 }} />


                <Stack direction={'row'} spacing={4}>
                    <IconButton aria-label={'call'} href={'tel:5207514314'}>
                        <FontAwesomeIcon icon={faPhone} />
                    </IconButton>

                    <IconButton aria-label={'email'} href={'mailto:briter@cox.net'}>
                        <FontAwesomeIcon icon={faEnvelope} />
                    </IconButton>
                </Stack>

            </Toolbar>
        </AppBar>
    );
};