import Grid from '@mui/material/Unstable_Grid2';
import BriterCard from '../briterCard/BriterCard';
import {BriterCardData} from '../briterCard/briterCard.type';
import Section from './Section';

export default function Clients() {
    const clients: BriterCardData[] = [
        {
            title: 'Bombardier Aerospace, Inc.',
            subtitle: 'Learjet',
            body:
                'As a pre-qualified consultant, BRITER performed AHERA asbestos inspections and acted ' +
                'as the Owners Representative in abatement projects throughout the Hughes Airport Facility Buildings B801, ' +
                'B803, B833 and B830.This work involved providing air monitoring and Project Management services to ensure ' +
                'contractor compliance to applicable OSHA and EPA Regulations.These projects required tight scheduling be maintained ' +
                'for necessary security and accountability.They also required flexibility in our personnel, because BRITER was asked ' +
                'to work over holidays and after normal work hours to accommodate Hughes Personnel and other contractors.',
            image: 'Bombardier.svg',
            visitLink: 'https://bombardier.com/en',
        },
        {
            title: 'Department of Veterans Affairs',
            subtitle: 'VA Medical Center',
            body:
                'BRITER provided this Tucson - based medical center with Certified Industrial Hygiene services in support of several ' +
                'separate and ongoing Indoor Air Quality concerns.The BRITER CIH performed; ' +
                'bioaerosol ( microbe sampling ), as well as other types of air monitoring for common indoor ' +
                'air quality parameter gases, an illumination study of selected areas and surface swab ' +
                'sampling for both bacterial and fungal infestations.Results of the survey found that this ' +
                'facilities air conditioning system was functioning properly and that an indoor air quality ' +
                'problem did not exist.On a separate instance, BRITER provided area air monitoring for ' +
                'selected constituents of engine exhaust after several staff members reported odors and ' +
                'symptoms from a contractor’s gasoline - powered welding unit.The exhaust was found to ' +
                'be entering the building through the HVAC system in nuisance concentrations.',
            image: 'va-og-image.png',
            visitLink: 'https://www.va.gov/',
        },
        {
            title: 'University of Arizona',
            subtitle: 'Research Park (Economic Development)',
            body:
                'On contract to the Economic Development Department, BRITER has actively assisted the onsite ' +
                'maintenance contractor, Grubb & Ellis with indoor air quality concerns that have arisen in ' +
                'recent past.  BRITER has provided Certified Industrial Hygiene and Indoor Air Quality ' +
                'Services in an attempt to determine the root cause of these concerns.  Our approach has ' +
                'involved interviews, area air monitoring and an evaluation of the heating, ventilation and ' +
                'air conditioning systems found in the effected building. ',
            image: 'ua-wide.jpg',
            visitLink: 'https://techparks.arizona.edu/',
        },
        {
            title: 'Abrams Airborne Manufacturing, Inc.',
            body:
                'BRITER provided Certified Industrial Hygiene ' +
                'and Certified Safety Professional services to Abrams metal working facilities. ' +
                'Consultation provided included exposure monitoring for noise, metal dusts and plating ' +
                'mists along with selected process solvents.  The BRITER CIH / Principal also acted as an ' +
                'OSHA Liaison to Abrams in compliance matters by review, interview, exposure sampling ' +
                'and representation before the AZ Department of Occupational Safety and Health in ' +
                'informal and formal hearings.',
            image: 'abrams.png',
            visitLink: 'https://www.abrams.com/aami_ad/',
        },
        {
            title: 'AT&T/US West',
            body:
                'BRITER provided this Denver-based Operator Assistance/Voice ' +
                'Messaging and Switching Center with Asbestos Services in support of an ongoing ' +
                'renovation and fire stopping project at their Tucson facility.  In the performance of this ' +
                'work, BRITER acted as the Owner’s Representative and was responsible for spot ' +
                'checking the abatement contractor’s compliance with OSHA and EPA regulation and air ' +
                'monitoring.  The air monitoring data was tabulated into weekly reports; the results were ' +
                'then presented weekly at the Contractor’s Meeting.  The work areas were sensitive because ' +
                'AT&T/US West staff were on duty in some areas, and the switching gear itself could be ' +
                'easily damaged.  BRITER showed it flexibility in meeting AT&T/US West’s requests for ' +
                'additional employee protection by varying shifts to accommodate their staffing hours and ' +
                'verifying use of special anti-static procedures to warrant against damage to sensitive ' +
                'equipment.  On another unrelated project, BRITER’s CIH was hired directly by ' +
                'AT&T/US West to provide expertise for a perceived Indoor Air Quality problem. ' +
                'BRITER’s investigation determined that the problem was not air quality related, but ' +
                'rather an Ergonomics (Man-Machine Interface) issue. Recommendations were made to ' +
                'remediate the situation.',
            image: 'att_2016.svg',
            imagePadding: '10px',
            visitLink: 'https://www.att.com/',
        },
        {
            title: 'Tucson Electric Power',
            subtitle: 'TEP',
            body:
                'BRITER provides Certified Industrial ' +
                'Hygiene Services in support of TEP’s Springerville power plant once or twice yearly. Our ' +
                'work is in support of their annual or bi-annual maintenance shutdowns.  During these ' +
                'shutdowns, contractors as well as TEP Personnel perform required and mandatory ' +
                'maintenance and repair procedures that involve welding, cutting and brazing of steel, ' +
                'stainless steel as well as exotic base metals.  BRITER worked with these maintenance and ' +
                'repair efforts to monitor exposures for welding fumes, hexavalent chromium, solvent ' +
                'emissions and occupational noise.',
            image: 'tep.webp',
            visitLink: 'https://www.tep.com/',
        },
        {
            title: 'KB Home Sales of Tucson',
            body: 'BRITER provided contracted Certified Industrial Hygiene and Mold Sampling and Project Management services to KB Home in support of their new homes and housing developments for approximately two years.  On occasion, a newly erected domicile will sustain water damage.  These damages can, on occasion, support mold growth.  BRITER acted to diagnose and sample these situations, and direct the restoration contractor to minimize disruption of the family and their household.  This work allowed us the opportunity to see a wide variety of water damage situations, with and without mold growth. This experience tempered our decisions and further sharpened our assessment process in complicated and, on occasion, politically charged mold clean-ups.',
            image: 'kb_home.png',
            visitLink: 'https://www.kbhome.com/',
        },
        {
            title: 'MORPAC Industries',
            body: 'Because of our excellent reputation, the Owner/Operators of MORPAC enlisted the BRITER CIH to perform lead (Pb) monitoring at their Tucson Facility.  Potential exposures to Pb had become a concern after it was discovered that several casting that MORPAC had been machining contained this metal in the alloy. BRITER prepared a sampling plan, performed the exposure monitoring, then based upon the results modified MORPAC’s process to limit these exposures.  Process changes have included changing the base metals used in casting and moving these operations away from typical grinders to the CNC - Machinist Area where isolation of the work and coolants could be used to reduce potential exposures.',
            image: 'morpac.gif',
            visitLink: 'https://morpac.com/',
        },
        {
            title: 'Honeywell',
            subtitle: 'Satellite Systems',
            body: 'Provided Certified Safety Professional (CSP) Services in support of this operation’s Environmental, Health and Safety Department.  BRITER provided a CSP to review process chemical substitutions with respect to their Corporation- wide phase out of chlorofluorocarbon-based solvents.  The CSP reviewed procedures currently ongoing and planned procedural changes to make recommendations for added protection based upon fire and electrical systems safety.',
            image: 'honeywell.png',
            imageObjectFit: 'contain',
            visitLink: 'https://www.honeywell.com/us/en',
        },
        {
            title: 'Sandia National Laboratories',
            body: 'Managed IH contract for another consultation group based out of Phoenix, Arizona.  This contract had Mr. Gladhart, CIH managing two other industrial hygienists and performing a broad range of Indoor Air Quality and Certified Industrial Hygiene surveys while under the control of the Sandia National Laboratories Environmental, Safety and Health Department.',
            image: 'sandia_labs.svg',
            imagePadding: '10px',
            visitLink: 'https://www.sandia.gov/',
        },
        {
            title: 'Ace Pipe Cleaning',
            body: 'Under contract, BRITER provides quarterly employee exposure monitoring for the heavy metals, Arsenic, Cadmium and Lead.  Our BRITER Certified Industrial Hygienist has also performed a re-write of the Ace Pipe Cleaning’s Respiratory Protection Program based upon 2014 – 2015 exposure data for these three heavy metals.',
            visitLink: 'https://acepipe.com/',
            image: 'ace_pipe_cleaning.png',
            // imagePadding: '10px',
        },
        {
            title: 'NESHAP/AHERA',
            subtitle: 'Training Instructor',
            body: 'Provided instructional training on a continuous basis to one of the largest training providers in the southwest.   Mr. Gladhart is the primary instructor for the AHERA Certified Asbestos Course Regimen.  Other training topics include industrial hygiene, respiratory protection, safety, chemical toxicity, lead (Pb) awareness and environmental pollution prevention/storm water courses.',
            visitLink: 'https://e-t-c.com/',
            image: 'neshap.jpg',
            // imagePadding: '10px',
        },
    ];

    // Fix the data without copy and pasting code
    clients.map((client) => {
        if (client.image) {
            client.image = 'client_images/' + client.image;
            client.imageHeight = 160;
            client.limitTextInitially = true;
        }

        return null;
    });

    return (
        <Section id={'clients'} title={'Clients'}>
            <Grid container spacing={2} direction={'row'} alignItems={'stretch'}>
                {clients.map((client, index) => (
                    <Grid md={12} lg={6} xl={4} key={index}>
                        <BriterCard {...client} />
                    </Grid>
                ))}
            </Grid>
        </Section>
    );
}
