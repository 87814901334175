import { Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import BriterCard from '../briterCard/BriterCard';
import { BriterCardData } from '../briterCard/briterCard.type';
import Section from './Section';

export function CIH() {


    const exposures: BriterCardData[] = [
        {
            title: 'Noise Exposure',
            body: 'Monitoring Occupational Noise for exposure (U.S. OSHA for overexposed) alleviates workplace concerns and potential risk. Risk, in this case is prevention of one of the most common workplace health effects and worker complaints.  If exposure to noise is evident, BRITER can help you and your organization with remedies that have been found effective in the prevention of hearing loss.',
            image: 'industrial_noise.jpg',
        },
        {
            title: 'Industrial Chemicals',
            body: 'Industrial chemicals can have a negative impact to your staff. BRITER helps ensure overexposures to these chemicals don’t occur. If an overexposure is found, we can assist you in correcting the situation and return your risk to a minimal level of concern.',
            image: 'MineFlocculation.jpg',
        },
        {
            title: 'Styrene and Epoxy-based Resins',
            body: 'Styrene resins impart a sweet smell into the workplace. However, their use can create unnecessary exposures that have long-term effects. Monitoring for both styrene and epoxy exposures is prudent practice.  BRITER can monitor these monomers and assist you in remedial actions that can attempt to minimize both or either employee exposure.',
            image: 'Cultured Marble.jpg'
        },
        {
            title: 'Urethane-based Paints and Spray Foams',
            body:
                'Isocyanates are a class of highly reactive chemicals with widespread industrial, commercial, and retail or consumer applications. ' +
                'Exposure to isocyanates may cause skin, eye and lung irritation, asthma, and “sensitization.” ',
            learnMoreLink: 'https://archive.epa.gov/epa/saferchoice/health-concerns-about-spray-polyurethane-foam.html',
            image: 'Aircraft Painting.jpg',
        },
        {
            title: 'Silica Dust',
            body:
                'Breathing in dust from silica-containing materials can lead to silicosis. Silica dust particles ' +
                'become trapped in lung tissue causing inflammation and scarring. The particles also reduce the lungs’ ' +
                'ability to take in oxygen. This condition is called silicosis. Silicosis results in permanent lung ' +
                'damage and is a progressive, debilitating, and sometimes fatal disease.',
            learnMoreLink: 'https://www.cdc.gov/niosh/topics/silica/risks.html',
            image: 'yellow_dust.jpg',
        },
        {
            title: 'Chrome Plating and Anodizing Operations',
            body: 'Beyond being an irritation to the nose, throat and lungs Chromium can cause cancer of the lungs.',
            learnMoreLink: 'https://www.osha.gov/sites/default/files/publications/hexavalent_chromium.pdf',
            image: 'HardChromePlating.jpg',
        },
    ];

    // Set data dynamically to avoid copy / paste code.
    exposures.map( exposure => {
        exposure.image = 'cih_images/' + exposure.image;
        exposure.imageHeight = 160;
        return null;
    } );

    const chemicalsBriterDealsWith: string[] = [
        'Oil and Latex Acrylic-based Paints',
        'Hexavalent Chromium from Paints and Alodine',
        'Flotation Chemicals and Flocculants',
        'All forms of Nuisance Dust',
        'Any Compound, Solvent or Reactant that presents an exposure hazard',
        'Ceramic Powders, Process Powders, Salts and Dry Compounds'
    ];

    return (
        <Section id='cih' title='Industrial Hygienists'>
            <p>
                BRITER’s American Board of Industrial Hygienists (ABIH) Certified Industrial Hygienists (CIH) have the
                knowledge and equipment to identify areas in you plant or workplace that could present potential
                chemical
                and physical exposure problems. BRITER can perform breathing zone and / or noise dosimetry exposure
                monitoring,
                calculate time weighted average exposures and compare them to the OSHA permissible exposure limits or
                other more
                stringent guidance values.
            </p>
            <p>
                We then can assist you in remediation these situations with cost effective
                recommendations reflected by the project’s outcome. Some of the physical and chemicals that can be
                assessed
                for potential exposures include:
            </p>

            <Grid container spacing={2} direction={'row'} alignItems={'stretch'}>
                {
                    exposures.map( ( exposure, index ) =>
                        <Grid md={12} lg={6} xl={4} key={index}>
                            <BriterCard {...exposure} />
                        </Grid>
                    )
                }

                <Grid xs={12}>
                    <Typography variant={'h5'}>We also deal with:</Typography>
                    <Divider></Divider>
                    <ul>
                        {chemicalsBriterDealsWith.map( ( extraExposure, index ) => <li key={index}>{extraExposure}</li> )}
                    </ul>
                </Grid>

                <Grid xs={12}>
                    <Typography variant={'h5'}>Written Compliance Services</Typography>
                    <Divider></Divider>
                    <br />
                    BRITER can provide written programs to satisfy government and safety organizational requirements.
                    We have written compliance documents for the following programs:
                    <ul>
                        <li>U.S. OSHA Hazard Communication Program (Employee Right-to-Know)</li>
                        <li>Chemical Hazard Program (Laboratory Right-to-Know)</li>
                        <li>Hearing Conservation Program</li>
                        <li>AHERA-based Asbestos Management Plans</li>
                        <li>Site-Specific Chemical and Physical Hazard Management Plans</li>
                    </ul>


                </Grid>
            </Grid>
        </Section>
    );
}
